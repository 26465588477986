/**
 * Gestion des données du formulaire
 */
const btnCommander = document.querySelector('#btn-modal-trigger');
const btnConnect = document.querySelector('#btnConnect');
const startProjectBtn = document.getElementById('start-project');
const btnModifyProject = document.getElementsByClassName('modify-project');
const btnAddPlans = document.querySelector('#add-plans-to-cart-button');

/** input select * */
const pignon = document.querySelector('#pignon');
const profondeur = document.querySelector('#profondeur');
const couverture = document.querySelector('#couverture');
const teinte = document.querySelector('#couleurBardage');
const accolementAv = document.querySelector('#pignonMitoyenAv');
const accolementAr = document.querySelector('#pignonMitoyenAr');
const accolementGa = document.querySelector('#pignonMitoyenGa');
const accolementDr = document.querySelector('#pignonMitoyenDr');
const ecoulementEaux = document.querySelector('#EcoulementEaux');
/** input radio * */
const fondations = document.getElementsByName('fondations');
const toitures = document.getElementsByName('toiture');
const gammes = document.getElementsByName('gamme');
const usages = document.getElementsByName('usage');
const delivery = document.getElementById('delivery');
const onsite = document.getElementById('onsite');
const department = document.getElementById('departement');

function setLocaleDefaultValue(nameLocaleData, defaultValue) {
  if (localStorage.getItem(nameLocaleData) === null) {
    localStorage.setItem(nameLocaleData, defaultValue);
  }
}

function setAndGetLocaleDefaultValueToFieldCouverture(nameLocaleDataCouverture, nameLocaleDataToiture) {
  let defaultValue = '2';
  if (localStorage.getItem(nameLocaleDataCouverture) === null) {
    localStorage.setItem(nameLocaleDataCouverture, '2');
    if (localStorage.getItem(nameLocaleDataToiture) === 'plat') {
      defaultValue = '5';
      localStorage.setItem(nameLocaleDataCouverture, '5');
    }
  }
  return defaultValue;
}

/* eslint-disable no-plusplus */
function updateSelectWithLocaleValue(elementSelectHTML, nameLocaleData, defaultValue) {
  for (let i = 0; i < elementSelectHTML.children.length; i++) {
    if (elementSelectHTML.children[i].value !== defaultValue || i !== 0) {
      elementSelectHTML.children[i].removeAttribute('selected');
      if (elementSelectHTML.children[i].value === localStorage.getItem(nameLocaleData)) {
        elementSelectHTML.children[i].setAttribute('selected', 'selected');
      }
    }
  }
}

/* eslint-enable no-plusplus */
/**
 * Gestion de la visibilité des options de l'élément select couverture
 * @param typeToiture
 */
function toggleSelectOptionsCouverture(typeToiture) {
  if (typeToiture === 'plat') {
    localStorage.setItem('couverture', '5');
    couverture.children[1].setAttribute('disabled', 'disabled');
    couverture.children[1].setAttribute('hidden', 'hidden');
    couverture.children[2].setAttribute('disabled', 'disabled');
    couverture.children[2].setAttribute('hidden', 'hidden');
    couverture.children[3].setAttribute('disabled', 'disabled');
    couverture.children[3].setAttribute('hidden', 'hidden');
    couverture.children[4].removeAttribute('disabled');
    couverture.children[4].removeAttribute('hidden');
  } else {
    if (localStorage.getItem('couverture') === '5') {
      localStorage.setItem('couverture', '1');
    }
    couverture.children[1].removeAttribute('disabled');
    couverture.children[1].removeAttribute('hidden');
    couverture.children[2].removeAttribute('disabled');
    couverture.children[2].removeAttribute('hidden');
    couverture.children[3].removeAttribute('disabled');
    couverture.children[3].removeAttribute('hidden');
    couverture.children[4].setAttribute('disabled', 'disabled');
    couverture.children[4].setAttribute('hidden', 'hidden');
  }
}

function updateInputRadioWithLocaleValueToToiture(elementSelectHTML, nameLocaleData) {
  elementSelectHTML.forEach((toiture) => {
    toiture.removeAttribute('checked');
    if (toiture.value === localStorage.getItem(nameLocaleData)) {
      toiture.setAttribute('checked', 'checked');
    }
    toiture.addEventListener('change', () => {
      localStorage.setItem(nameLocaleData, toiture.value);
      toggleSelectOptionsCouverture(localStorage.getItem(nameLocaleData));
    });
  });
}

function updateInputRadioWithLocaleValue(elementSelectHTML, nameLocaleData) {
  elementSelectHTML.forEach((item) => {
    item.removeAttribute('checked');
    if (item.value === localStorage.getItem(nameLocaleData)) {
      item.setAttribute('checked', 'checked');
    }
    item.addEventListener('change', () => {
      localStorage.setItem(nameLocaleData, item.value);
    });
  });
}

function addSelectEvent(elementSelectHTML, nameLocaleData) {
  elementSelectHTML.addEventListener('change', () => {
    localStorage.setItem(nameLocaleData, elementSelectHTML.value);
  });
}


if (btnAddPlans !== null) {
  btnAddPlans.addEventListener('click', (e) => {
    e.preventDefault();
    const url = new URL(btnAddPlans.href);
    let newUrlHref = url.href.slice(0, -1);
    newUrlHref = `${newUrlHref + localStorage.getItem('idProjet')}&prixProduit=${localStorage.getItem('idProjet')}`;
    url.pathname = newUrlHref;
    window.location.href = url.href;
  });
}
if (btnConnect !== null) {
  btnConnect.addEventListener('click', (e) => {
    e.preventDefault();
    const url = new URL(btnConnect.href);
    if (localStorage.getItem('idProjet')) {
      url.searchParams.set('p', localStorage.getItem('idProjet'));
    }
    window.location.href = url.href;
  });
}

/** Ajout des données lors du projet lors d'une demande de modification* */
function setLocalData() {
  const project = JSON.parse(this.dataset.project);
  if (!project.planIsPaid && !project.advanceIsPaid) {
    localStorage.setItem('idProjet', project.idProjet);
    localStorage.setItem('usageName', project.usageName);
    localStorage.setItem('gammeId', project.gammeId);
    localStorage.setItem('largeurPignon', project.largeurPignon);
    localStorage.setItem('profondeurBassePente', project.profondeurBassePente);
    localStorage.setItem('toiture', project.toiture);
    localStorage.setItem('couverture', project.couverture);
    localStorage.setItem('teinte', project.teinte);
    localStorage.setItem('sol', project.sol);
    localStorage.setItem('accolement', project.accolement);
    localStorage.setItem('ecoulementEaux', project.ecoulementEaux);
    localStorage.setItem('codepostal', project.codepostal);
  }
}

/* eslint-disable no-plusplus */
/** Gestion du bouton modification dans mes projets 3D * */
for (let i = 0; i < btnModifyProject.length; i++) {
  btnModifyProject[i].addEventListener('click', setLocalData);
}
/* eslint-enable no-plusplus */
/** Gestion de la mise à jour du formulaire projet 3D * */
if (btnCommander !== null) {
  onsite.addEventListener('change', () => {
    localStorage.setItem('codepostal', '0');
  });
  delivery.addEventListener('change', () => {
    let inputValue = department.value;
    inputValue = inputValue.replace(/\D/g, '');
    if (inputValue.length === 1) {
      inputValue = `0${inputValue}`;
    }
    department.value = inputValue;
    localStorage.setItem('codepostal', inputValue);
  });
  department.addEventListener('change', () => {
    let inputValue = department.value;
    inputValue = inputValue.replace(/\D/g, '');
    if (inputValue.length === 4) {
      inputValue = `0${inputValue}`;
    }
    /*if (inputValue > 95) {
      inputValue = 95;
    }*/
    department.value = inputValue;
    localStorage.setItem('codepostal', inputValue);
  });

  /** TAB HOME */
  if (startProjectBtn !== null) {
    startProjectBtn.innerHTML = localStorage.getItem('idProjet') ? 'MODIFIER MON PROJET' : 'COMMENCER MON PROJET';
  }
  /** TAB CODEPOSTAL */
  if (localStorage.getItem('codepostal')) {
    delivery.checked = 'checked';
    department.value = localStorage.getItem('codepostal');
  }
  if (localStorage.getItem('codepostal') === '0') {
    onsite.checked = 'checked';
    department.value = '49';
  }
  if (delivery.checked) {
    setLocaleDefaultValue('codepostal', department.value);
  }
  if (onsite.checked) {
    setLocaleDefaultValue('codepostal', 0);
  }
  /** TAB USAGE - CHAMP USAGE * */
  if (usages !== null) {
    setLocaleDefaultValue('usageName', 'stockage');
    updateInputRadioWithLocaleValue(usages, 'usageName');
  }
  /** TAB GAMME - CHAMP GAMME * */
  if (gammes !== null) {
    setLocaleDefaultValue('gammeId', 'Boréal');
    updateInputRadioWithLocaleValue(gammes, 'gammeId');
  }
  /** TAB DIMENSIONS - CHAMP PIGNON * */
  if (pignon !== null) {
    setLocaleDefaultValue('largeurPignon', '2000');
    updateSelectWithLocaleValue(pignon, 'largeurPignon', '2000');
    addSelectEvent(pignon, 'largeurPignon');
  }
  /** TAB DIMENSIONS - CHAMP PROFONDEUR * */
  if (profondeur !== null) {
    setLocaleDefaultValue('profondeurBassePente', '4000');
    updateSelectWithLocaleValue(profondeur, 'profondeurBassePente', '4000');
    addSelectEvent(profondeur, 'profondeurBassePente');
  }
  /** TAB TOITURE - CHAMP TOITURE * */
  if (toitures !== null) {
    setLocaleDefaultValue('toiture', '2pentes');
    updateInputRadioWithLocaleValueToToiture(toitures, 'toiture');
    toggleSelectOptionsCouverture(localStorage.getItem('toiture'));
    /** TAB TOITURE - CHAMP COUVERTURE * */
    if (couverture !== null) {
      const defaultValue = setAndGetLocaleDefaultValueToFieldCouverture('couverture', 'toiture');
      updateSelectWithLocaleValue(couverture, 'couverture', defaultValue);
      addSelectEvent(couverture, 'couverture');
    }
  }
  /** TAB TEINTE - CHAMP TEINTE * */
  if (teinte !== null) {
    setLocaleDefaultValue('teinte', '#ae0000');
    updateSelectWithLocaleValue(teinte, 'teinte', '#ae0000');
    addSelectEvent(teinte, 'teinte');
  }
  /** TAB SOL - CHAMP SOL * */
  if (fondations !== null) {
    setLocaleDefaultValue('sol', 'dalle-beton');
    updateInputRadioWithLocaleValue(fondations, 'sol');
  }
  /** TAB ENVIRONNEMENT - CHAMP MITOYEN * */
  if (accolementDr !== null) {
    setLocaleDefaultValue('pignonMitoyenDr', '1');
    updateInputCheckboxWithLocaleValue(accolementDr, 'pignonMitoyenDr');
  }
  if (accolementGa !== null) {
    setLocaleDefaultValue('pignonMitoyenGa', '1');
    updateInputCheckboxWithLocaleValue(accolementGa, 'pignonMitoyenGa');
  }
  if (accolementAv !== null) {
    setLocaleDefaultValue('pignonMitoyenAv', '1');
    updateInputCheckboxWithLocaleValue(accolementAv, 'pignonMitoyenAv');
  }
  if (accolementAr !== null) {
    setLocaleDefaultValue('pignonMitoyenAr', '0');
    updateInputCheckboxWithLocaleValue(accolementAr, 'pignonMitoyenAr');
  }
  /** TAB ENVIRONNEMENT - CHAMP ECOULEMENTEAUX * */
  if (ecoulementEaux !== null) {
    setLocaleDefaultValue('ecoulementEaux', '0');
    updateSelectWithLocaleValue(ecoulementEaux, 'ecoulementEaux', '0');
    addSelectEvent(ecoulementEaux, 'ecoulementEaux');
  }
}

/** Ajout des données lors du projet lors d'une demande de modification* */
function setLocalData() {
  const project = JSON.parse(this.dataset.project);
  console.log(project);
  if (!project.planIsPaid && !project.advanceIsPaid) {
    localStorage.setItem('idProjet', project.idProjet);
    localStorage.setItem('usageName', project.usageName);
    localStorage.setItem('gammeId', project.gammeId);
    localStorage.setItem('largeurPignon', project.largeurPignon);
    localStorage.setItem('profondeurBassePente', project.profondeurBassePente);
    localStorage.setItem('toiture', project.toiture);
    localStorage.setItem('couverture', project.couverture);
    localStorage.setItem('teinte', project.teinte);
    localStorage.setItem('sol', project.sol);
    localStorage.setItem('accolementAv', project.accolementAv);
    localStorage.setItem('accolementAr', project.accolementAr);
    localStorage.setItem('accolementGa', project.accolementGa);
    localStorage.setItem('accolementDr', project.accolementDr);
    localStorage.setItem('ecoulementEaux', project.ecoulementEaux);
  }
}

/** Suppression des données au chargement de la page hors formulaire projet 3D * */
if (!btnCommander) {
  localStorage.removeItem('idProjet');
  localStorage.removeItem('usageName');
  localStorage.removeItem('gammeId');
  localStorage.removeItem('largeurPignon');
  localStorage.removeItem('profondeurBassePente');
  localStorage.removeItem('toiture');
  localStorage.removeItem('couverture');
  localStorage.removeItem('teinte');
  localStorage.removeItem('sol');
  localStorage.removeItem('accolementAv');
  localStorage.removeItem('accolementAr');
  localStorage.removeItem('accolementDr');
  localStorage.removeItem('accolementGa');
  localStorage.removeItem('ecoulementEaux');
  localStorage.removeItem('codepostal');
}

function setLocaleDefaultValue(nameLocaleData, defaultValue) {
  if (localStorage.getItem(nameLocaleData) === null) {
    localStorage.setItem(nameLocaleData, defaultValue);
  }
}

function setAndGetLocaleDefaultValueToFieldCouverture(nameLocaleDataCouverture, nameLocaleDataToiture) {
  let defaultValue = '2';
  if (localStorage.getItem(nameLocaleDataCouverture) === null) {
    localStorage.setItem(nameLocaleDataCouverture, '2');
    if (localStorage.getItem(nameLocaleDataToiture) === 'plat') {
      defaultValue = '5';
      localStorage.setItem(nameLocaleDataCouverture, '5');
    }
  }
  return defaultValue;
}

function updateSelectWithLocaleValue(elementSelectHTML, nameLocaleData, defaultValue) {
  for (let i = 0; i < elementSelectHTML.children.length; i++) {
    if (elementSelectHTML.children[i].value !== defaultValue || i !== 0) {
      elementSelectHTML.children[i].removeAttribute('selected');
      if (elementSelectHTML.children[i].value === localStorage.getItem(nameLocaleData)) {
        elementSelectHTML.children[i].setAttribute('selected', 'selected');
      }
    }
  }
}

function updateInputRadioWithLocaleValueToToiture(elementSelectHTML, nameLocaleData) {
  elementSelectHTML.forEach((toiture) => {
    toiture.removeAttribute('checked');
    if (toiture.value === localStorage.getItem(nameLocaleData)) {
      toiture.setAttribute('checked', 'checked');
    }
    toiture.addEventListener('change', () => {
      localStorage.setItem(nameLocaleData, toiture.value);
      toggleSelectOptionsCouverture(localStorage.getItem(nameLocaleData));
    });
  });
}

function updateInputRadioWithLocaleValue(elementSelectHTML, nameLocaleData) {
  elementSelectHTML.forEach((item) => {
    item.removeAttribute('checked');
    if (item.value === localStorage.getItem(nameLocaleData)) {
      item.setAttribute('checked', 'checked');
    }
    item.addEventListener('change', () => {
      localStorage.setItem(nameLocaleData, item.value);
    });
  });
}


function updateInputCheckboxWithLocaleValue(elementSelectHTML, nameLocaleData) {
  elementSelectHTML.removeAttribute('checked');
  if (elementSelectHTML.value === localStorage.getItem(nameLocaleData)) {
    elementSelectHTML.setAttribute('checked', 'checked');
  }
  elementSelectHTML.addEventListener('change', () => {
    localStorage.setItem(nameLocaleData, elementSelectHTML.value);
  });
}

function addSelectEvent(elementSelectHTML, nameLocaleData) {
  elementSelectHTML.addEventListener('change', () => {
    localStorage.setItem(nameLocaleData, elementSelectHTML.value);
  });
}

/**
 * Gestion de la visibilité des options de l'élément select couverture
 * @param typeToiture
 */
function toggleSelectOptionsCouverture(typeToiture) {
  if (typeToiture === 'plat') {
    localStorage.setItem('couverture', '5');
    couverture.children[1].setAttribute('disabled', 'disabled');
    couverture.children[1].setAttribute('hidden', 'hidden');
    couverture.children[2].setAttribute('disabled', 'disabled');
    couverture.children[2].setAttribute('hidden', 'hidden');
    couverture.children[3].setAttribute('disabled', 'disabled');
    couverture.children[3].setAttribute('hidden', 'hidden');
    couverture.children[4].removeAttribute('disabled');
    couverture.children[4].removeAttribute('hidden');
  } else {
    if (localStorage.getItem('couverture') === '5') {
      localStorage.setItem('couverture', '1');
    }
    couverture.children[1].removeAttribute('disabled');
    couverture.children[1].removeAttribute('hidden');
    couverture.children[2].removeAttribute('disabled');
    couverture.children[2].removeAttribute('hidden');
    couverture.children[3].removeAttribute('disabled');
    couverture.children[3].removeAttribute('hidden');
    couverture.children[4].removeAttribute('disabled');
    couverture.children[4].removeAttribute('hidden');
  }
}

/** MODALE VALIDATION FORM PROJET */
/* Boutons de la modale */
const modalTriggers4 = document.querySelectorAll('.modal-trigger4');

/* Modale */
const modalContainer4 = document.querySelector('.modal-configurateur-container4');

function toggleModal4() {
  modalContainer4.classList.toggle('active');
  if (modalContainer4.classList.contains('active')) {
    envoiDemandePdf(true);
  }
}

/* O/I modale */
modalTriggers4.forEach(trigger => trigger.addEventListener('click', toggleModal4));
/** MODALE VALIDATION MENU PROJET */
/* Boutons de la modale */
const modalTriggers2 = document.querySelectorAll('.modal-trigger2');

/* Modale */
const modalContainer2 = document.querySelector('.modal-configurateur-container2');
const linkAddToCart = document.querySelector('#add-to-cart-project');
const hrefBase = linkAddToCart.href;

function toggleModal2() {
  if (this.dataset.project) {
    linkAddToCart.href = hrefBase + this.dataset.project;
  }
  modalContainer2.classList.toggle('active');
}

/* O/I modale */
modalTriggers2.forEach(trigger => trigger.addEventListener('click', toggleModal2));

/** MODALE SYNTHESE FORM PROJET */
/* Boutons de la modale */
const modalTriggers3 = document.querySelectorAll('.modal-trigger3');
/* Modale */
const modalContainer3 = document.querySelector('.modal-configurateur-container3');

function toggleModal3() {
  // configurateur/json-project/
  modalContainer3.classList.toggle('active');
  if (modalContainer3.classList.contains('active')) {
    envoiDemandePdf(false);
  }
}

/* O/I modale */
modalTriggers3.forEach(trigger => trigger.addEventListener('click', toggleModal3));

/** MODAL INFOS PROJECT EXPIRED */
/* Boutons de la modale */
const modalTriggersEstimateExpired = document.querySelectorAll('.modal-trigger-estimate-expired');
/* Modale */
const modalContainerEstimateExpired = document.querySelector('.modal-configurateur-container-estimate-expired');

function toggleModalEstimateExpired() {
  // configurateur/json-project/
  modalContainerEstimateExpired.classList.toggle('active');
}

/* O/I modale */
modalTriggersEstimateExpired.forEach(trigger => trigger.addEventListener('click', toggleModalEstimateExpired));
