import "./css/custom.scss";
import 'semantic-ui-css/components/accordion';
import 'semantic-ui-css/components/checkbox';
import 'semantic-ui-css/components/dimmer';
import 'semantic-ui-css/components/dropdown';
import 'semantic-ui-css/components/rating';
import 'semantic-ui-css/components/sidebar';
import 'semantic-ui-css/components/tab';
import 'semantic-ui-css/components/transition';
import 'semantic-ui-css/components/popup';
import 'semantic-ui-css/components/api';
import './configurateur';
import Glide from "@glidejs/glide";

/* Burger Menu */

const burger = document.getElementById('burger')
const mobileNav = document.getElementsByClassName('navbar__links')

burger.addEventListener('click', function () {
  if (mobileNav[0].style.display === 'none') {
    mobileNav[0].style.display = 'flex'
  } else {
    mobileNav[0].style.display = 'none'
  }
})

if (document.getElementById('carousel-home') !== null) {
  let glide = new Glide('#carousel-home', {
    type: 'carousel',
    autoplay: false
  })

  glide.mount()
}
$('.ui.sidebar')
  .sidebar('toggle')
;


/* Register form */

const particulier = document.getElementById('sylius_customer_registration_typeUser_0')
const pro = document.getElementById('sylius_customer_registration_typeUser_1')
const collectivite = document.getElementById('sylius_customer_registration_typeUser_2')
const wrapperPro = document.getElementsByClassName('pro')

if (pro) {
  if (pro.checked) {
    wrapperPro[0].style.display = 'flex';
  }
  pro.addEventListener('click', function () {
    wrapperPro[0].style.display = 'flex';
  })
}
if (collectivite) {
  collectivite.addEventListener('click', function () {
    wrapperPro[0].style.display = 'none';
  })
}
if (particulier) {
  particulier.addEventListener('click', function () {
    wrapperPro[0].style.display = 'none';
  })
}

if (document.querySelector('#sylius_customer_registration_numeroSiret')) {
  const numeroSiret = document.querySelector('#sylius_customer_registration_numeroSiret')

  numeroSiret.addEventListener('keyup', () => {
    numeroSiret.value = numeroSiret.value.replace(' ', '')
  })
}

/* Début vérification des adresses de facturation et de livraison */

const checkAddressButton = document.getElementById('check-address');
if (checkAddressButton !== null) {
  /* Champs du formulaire */
  const inputBillingPostcode = document.getElementById("sylius_checkout_address_billingAddress_postcode");
  const inputBillingStreet = document.getElementById("sylius_checkout_address_billingAddress_street");
  const inputBillingCity = document.getElementById("sylius_checkout_address_billingAddress_city");
  const inputShippingPostcode = document.getElementById("sylius_checkout_address_shippingAddress_postcode");
  const inputShippingStreet = document.getElementById("sylius_checkout_address_shippingAddress_street");
  const inputShippingCity = document.getElementById("sylius_checkout_address_shippingAddress_city");
  const inputIsSameShippingAddress = document.getElementById('sylius_checkout_address_differentShippingAddress');

  /* Modale */
  const modalContainer = document.querySelector(".modal-address-container");
  /* Champs d'accueil des adresses à vérifier */
  const addressBillingTextModal = document.getElementById('modal-address-billing-text');
  const addressShippingTextModal = document.getElementById('modal-address-shipping-text');
  const addressBillingErrorModal = document.getElementById('modal-address-billing-error');
  const addressShippingErrorModal = document.getElementById('modal-address-shipping-error');
  /* Boutons de la modale */
  const modalTriggers = document.querySelectorAll(".modal-trigger");

  const validAddressButton = document.getElementById('valid-address');
  /* Bouton étape suivante */
  const nextStep = document.getElementById('next-step');

  /* Variables tampon en attente de la validation */

  let billingDataList = {
    'type': 'billing',
    'name': null,
    'city': null,
    'postcode': null
  };
  let shippingDataList = {
    'type': 'shipping',
    'name': null,
    'city': null,
    'postcode': null
  };

  /* Si modification des champs adresses après vérification */
  [inputBillingPostcode, inputBillingStreet, inputBillingCity, inputShippingPostcode, inputShippingStreet, inputShippingCity].forEach((item) => {
    item.addEventListener('keydown', (e) => {
      if (checkAddressButton.classList.contains('display-button-address')) {
        checkAddressButton.classList.toggle('display-button-address');
        nextStep.setAttribute('disabled', 'disabled');
      }
    });
  });

  /* Prépare et affiche la demande de vérification des adresses de facturation et de livraison */
  checkAddressButton.addEventListener('click', async () => {
    addressBillingErrorModal.innerText = null;
    addressShippingErrorModal.innerText = null;
    let urlBilling = setUrl(inputBillingStreet.value, inputBillingPostcode.value);
    await fetchAddressAndSetModal(urlBilling, addressBillingTextModal, addressBillingErrorModal, billingDataList);
    if (inputIsSameShippingAddress.checked) {
      let urlShipping = setUrl(inputShippingStreet.value, inputShippingPostcode.value);
      await fetchAddressAndSetModal(urlShipping, addressShippingTextModal, addressShippingErrorModal, shippingDataList);
    } else {
      await fetchAddressAndSetModal(urlBilling, addressShippingTextModal, addressShippingErrorModal, shippingDataList);
    }
    toggleModal();
  })

  /* Le client valide les adresses */
  validAddressButton.addEventListener("click", () => {
    inputBillingPostcode.value = billingDataList.postcode;
    inputBillingStreet.value = billingDataList.name;
    inputBillingCity.value = billingDataList.city;
    inputShippingPostcode.value = shippingDataList.postcode;
    inputShippingStreet.value = shippingDataList.name;
    inputShippingCity.value = shippingDataList.city;
    checkAddressButton.classList.toggle('display-button-address')
    nextStep.removeAttribute('disabled');
  })

  /* Sécurité double click*/
  checkAddressButton.addEventListener('dblclick', (e) => {
    e.preventDefault();
  });
  validAddressButton.addEventListener('dblclick', (e) => {
    e.preventDefault();
  });

  /* Demande des informations pour la vérification d'une adresse et mise à jour de la modale */
  async function fetchAddressAndSetModal(url, modalElementText, modalElementError, dataList) {
    await fetch(encodeURI(url), {
      method: 'GET',
      headers: {"Accept": "application/json"}
    }).then(response => response.json())
      .then(response => {
        setModal(modalElementText, modalElementError, response.features[0].properties, dataList);
      })
      .catch(error => {
        setModal(modalElementText, modalElementError, null, dataList);
        // throw new Error('Impossible de contacter le serveur: ' + error);
      });
  }

  /* Ajout des informations récupérées dans la modale */
  function setModal(modalElementText, modalElementError, response, dataList) {
    if (response === null || (response.name === undefined || response.city === undefined || response.postcode === undefined)) {
      modalElementError.innerText = "L'adresse renseignée comporte une erreur, n'est pas encore référencée ou ne peux être vérifiée. " +
        "Voulez-vous quand même la valider ?";
      if (dataList.type === 'billing' || !inputIsSameShippingAddress.checked) {
        dataList.name = inputBillingStreet.value;
        dataList.city = inputBillingCity.value;
        dataList.postcode = inputBillingPostcode.value;
        modalElementText.innerText = inputBillingStreet.value + ', ' + inputBillingCity.value + ' (' + inputBillingPostcode.value + ').';
      }
      if (dataList.type === 'shipping' && inputIsSameShippingAddress.checked) {
        dataList.name = inputShippingStreet.value;
        dataList.city = inputShippingCity.value;
        dataList.postcode = inputShippingPostcode.value;
        modalElementText.innerText = inputShippingStreet.value + ', ' + inputShippingCity.value + ' (' + inputShippingPostcode.value + ').';
      }
      return;
    }
    dataList.name = response.name;
    dataList.city = response.city;
    dataList.postcode = response.postcode;
    modalElementText.innerText = response.name + ', ' + response.city + ' (' + response.postcode + ').';
  }

  /* Création de l'url pour la demande d'informations */
  function setUrl(street, postcode) {
    return 'https://api-adresse.data.gouv.fr/search/?q=' + street + '&postcode=' + postcode;
  }

  /* O/I modale */
  modalTriggers.forEach(trigger => trigger.addEventListener("click", toggleModal));

  function toggleModal() {
    modalContainer.classList.toggle("active")
  }
}

/* Fin vérification des adresses de facturation et de livraison */
